import React from 'react';
import { DontDo, DontDoItem, Paragraph, SectionSubhead } from 'docComponents';

const ListHeight = ({ type }) => {
  return (
    <div>
      <SectionSubhead>List Height</SectionSubhead>
      <Paragraph>
        Allow enough space in your interface or adjust the max-height to prevent
        the option list from expanding beyond the screen.
      </Paragraph>
      <Paragraph>
        By default, 6.5 items will show when the option list is expanded. If you
        use option groups or custom options, adjust the max-height to ensure it
        falls on a ½ item to signal the user can scroll.
      </Paragraph>

      <DontDo gridLayout="2">
        <DontDoItem
          type="dont"
          text="allow overflow to land between options and give the impression all options are visible."
          img={
            type === 'single'
              ? 'selects-overflow-dont'
              : 'selects-lookupoverflow-dont'
          }
        />
        <DontDoItem
          type="do"
          text="size the maximum height to split an option and make scrolling obvious."
          img={
            type === 'single'
              ? 'selects-overflow-do'
              : 'selects-lookupoverflow-do'
          }
        />
      </DontDo>
    </div>
  );
};

export default ListHeight;
