import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageWithSubNav,
  PageNavigation,
  PageNavigationLink,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import {
  AvatarUser,
  FormModifier,
  OptionTitle,
  OptionSubtitle,
  SingleSelect,
} from 'hudl-uniform-ui-components';
import ListHeight from '../partials/_ListHeight';
import OptionGroups from '../partials/_OptionGroups';
import singleSelectPreviewData from '../../../../data/previews/single-select.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const options = [
  { label: 'Kareem Abdul-Jabbar', value: 'kareem' },
  { label: 'Marv Albert', value: 'marv', disabled: true },
  { label: 'Al Attles', value: 'al' },
  { label: 'Red Auerbach', value: 'red' },
  { label: 'Elgin Baylor', value: 'elgin' },
  { label: 'Dave Bing', value: 'dave' },
  { label: 'Larry Bird', value: 'larry' },
];

const customOptions = [
  { label: 'Kareem Abdul-Jabbar', value: 'kareem', initials: 'KAJ' },
  { label: 'Marv Albert', value: 'marv', initials: 'MA' },
  { label: 'Al Attles', value: 'al', initials: 'AA' },
  { label: 'Red Auerbach', value: 'red', initials: 'RA' },
  { label: 'Elgin Baylor', value: 'elgin', initials: 'EB' },
  { label: 'Dave Bing', value: 'dave', initials: 'DB' },
  { label: 'Larry Bird', value: 'larry', initials: 'LB' },
];

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Single Select"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Selects"
        tierThree="Single Select"
      />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Size">
        <Paragraph>
          Consider the interface’s density and the select’s position when
          choosing between the three available sizes. (Sizing itself is
          controlled by the{' '}
          <Link href="/components/forms/form-modifier" isDesignCodeLink>
            form modifier component
          </Link>
          .)
        </Paragraph>
        <ComponentPreview
          name="SingleSelectSize"
          layout="halfWidth"
          previewData={singleSelectPreviewData.sizes}>
          <FormModifier>
            <SingleSelect
              ignoreThisComponent
              placeholder="Select an athlete"
              options={options}
            />
          </FormModifier>
        </ComponentPreview>
      </Section>
      <Section title="Mobile">
        <Paragraph>
          Use{' '}
          <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/pickers/">
            a picker
          </Link>{' '}
          to display the results when selecting a single option. If using our
          React Native component, this is done for you.
        </Paragraph>
      </Section>
      <Section title="Usage">
        <SectionSubhead>List Length</SectionSubhead>
        <Paragraph>
          A single select works for shorter lists with familiar options, ideally
          5-15 items. For fewer options, use a
          <Link href="/components/forms/radio" isDesignCodeLink>
            {' '}
            radio
          </Link>
          . For more than 15, use a{' '}
          <Link href="/components/selects/lookup-select" isDesignCodeLink>
            lookup select
          </Link>
          .
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="make the list too long with options the user may not predict."
            img="selects-listlength-dont"
          />
          <DontDoItem
            type="do"
            text="keep the list short so typeahead is not needed."
            img="selects-listlength-do"
          />
        </DontDo>

        <SectionSubhead>Options</SectionSubhead>
        <Paragraph>
          Option content should be familiar to the user–they should have an idea
          of what’s in store before opening the select.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="frame every item differently in an unhelpful order."
            img="selects-order-dont"
          />
          <DontDoItem
            type="do"
            text="provide a logical list of related items."
            img="selects-order-do"
          />
        </DontDo>

        <OptionGroups type="single" />

        <SectionSubhead>Custom Options</SectionSubhead>
        <Paragraph>
          Options are not limited to a single line of text. Additional text and
          images are allowed, just be consistent with the content.
        </Paragraph>
        <Paragraph>
          A common custom option example is listing athletes with avatars–it
          should always be for the sake of helping users find what they’re
          looking for.
        </Paragraph>
        <ComponentPreview name="SingleSelectCustom" layout="halfWidth">
          <FormModifier size="medium">
            <SingleSelect
              options={customOptions}
              placeholder="Select an athlete"
              customOption={({ optionData }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ paddingRight: '0.5rem' }}>
                    <AvatarUser initials={optionData.initials} />
                  </div>

                  <div>
                    <OptionTitle>{optionData.label}</OptionTitle>

                    <OptionSubtitle>{optionData.description}</OptionSubtitle>
                  </div>
                </div>
              )}
            />
          </FormModifier>
        </ComponentPreview>
        <Paragraph>
          If you do include additional text and/or images, the closed height
          cannot change once an option is selected.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="expand the closed state for large selected options."
            img="selects-placeholdersize-dont"
          />
          <DontDoItem
            type="do"
            text="modify the selected item preview to match the placeholder size."
            img="selects-placeholdersize-do"
          />
        </DontDo>

        <ListHeight type="single" />
      </Section>

      <Section title="Microcopy">
        <SectionSubhead>Placeholder</SectionSubhead>
        <Paragraph>
          Single select placeholders should read “Select a/an __”. By limiting
          the CTA to <em>select</em>, we’re suggesting there is a list to choose
          from. The article a/an makes it clear they can only choose one.
        </Paragraph>
        <SectionSubhead>Options</SectionSubhead>
        <Paragraph>
          Single select options are typically single words or names. If your
          options go beyond that, be sure to follow the{' '}
          <Link href="/words/content-elements/labels-non-label-text#Non-Label%20UI%20Text">
            non-label text guidelines
          </Link>
          .
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable>
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
